@mixin devices ($breakpoint) {
    //the name of the mixin is devices

    @if $breakpoint ==desktop {
        @media only screen and (min-width: 1000px) {
            @content;
        }
    }

    @if $breakpoint ==tablet {
        @media only screen and (max-width: 1000px) {
            @content;
        }
    }

    @if $breakpoint ==mobile {
        @media only screen and (max-width: 481px) {
            @content;
        }
    }
}

.layered-steps {
    background: none;

    @include devices(desktop) {
        background-image: url("../Assets/layered-steps-haikei.svg") !important;
        aspect-ratio: 960/540 !important;
        width: 100% !important;
        background-repeat: no-repeat !important;
        background-position: center !important;
        background-size: cover !important;
    }

    @include devices(tablet) {
        background: none;
    }


    @include devices(mobile) {
        background: none;
    }
}