@mixin devices ($breakpoint) {
      //the name of the mixin is devices

      @if $breakpoint ==desktop {
            @media only screen and (min-width: 1000px) {
                  @content;
            }
      }

      @if $breakpoint ==tablet {
            @media only screen and (max-width: 1000px) {
                  @content;
            }
      }

      @if $breakpoint ==mobile {
            @media only screen and (max-width: 481px) {
                  @content;
            }
      }
}

.dashboard-box{
      position: relative;
      display: flex;
      flex-direction: column;
      min-width: 0;
      word-wrap: break-word;
      background-color: #1a2233;
      // background-image: linear-gradient(
      //   to right,
      //   #202020,
      //   #252525,
      //   #272727,
      // );
      background-clip: border-box;
      border-radius: 2rem;
      border: 1px solid rgba(0,0,0,.125);
      padding: 2rem;
      margin-bottom: 2rem !important; 
      box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}


